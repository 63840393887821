import { ClassNames, Global } from '@emotion/react'
import first from 'lodash/first'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import React, { useCallback, useState, useEffect } from 'react'
import tw, { css, theme } from 'twin.macro'
import { ReactComponent as Arrow } from '../images/icon-arrow-right.svg'
import { ReactComponent as CaretLarge } from '../images/icon-caret-down-lg.svg'
import { ReactComponent as Caret } from '../images/icon-caret-down.svg'
import { ReactComponent as CaretRight } from '../images/icon-caret-right.svg'
import { global } from '../styles/global'
import { max } from '../utils/breakpoints'
import { format } from '../utils/date'
import { LinkType, StyleType } from '../utils/prop-types'
import Button from './button'
import Hamburger from './hamburger'
import Heading from './heading'
import Link from './link'
import Text from './text'
import LanguagePicker from './language-picker'

const NavyLinkButton = ({ label, link, style }) => (
  <div
    css={[
      css`
        ${tw`flex flex-row`}
        &:hover {
          svg {
            ${tw`lg:translate-x-2`}
          }
        }
      `,
      style,
    ]}
  >
    <Link to={link} style={tw`flex flex-row items-start`}>
      <Text content={label} textType="body-base" style={tw`font-medium`} />
      <Arrow css={tw`h-6 ml-2.5 transform transition-transform duration-300 ease-in-out`} />
    </Link>
  </div>
)

NavyLinkButton.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  style: StyleType,
}

const CyanLinkButton = ({ label, link, style }) => (
  <div
    css={[
      css`
        ${tw`flex flex-row`}
        &:hover {
          svg {
            ${tw`lg:translate-x-2`}
          }
        }
      `,
      style,
    ]}
  >
    <Link to={link} style={tw`flex flex-row items-center`}>
      <Text content={label} style={tw`text-xs font-medium leading-loose text-secondary-500`} />
      <CaretRight
        css={tw`h-2.5 ml-6 transform transition-transform duration-300 ease-in-out fill-secondary`}
      />
    </Link>
  </div>
)

CyanLinkButton.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  style: StyleType,
}

const Dropdown = ({ item }) => (
  <div
    css={[
      css`
        ${tw`hidden lg:(block pointer-events-none transition-opacity duration-300
          ease-in-out opacity-0 fixed top-desktop-header w-full inset-x-0 bg-white)`}
      `,
    ]}
  >
    <div css={[global`layout.container`, global`layout.grid`, tw`w-full`]}>
      <div css={tw`lg:(mt-10 mb-16 col-span-3) xl:col-span-3`}>
        <Heading content={item.descriptionNode} headingType="h5" />
        {item.link && (
          <CyanLinkButton label={item.link.label} link={item.link.link} style={tw`text-sm mt-7`} />
        )}
      </div>
      <div css={tw`flex lg:(mt-10 mb-16 col-span-9) xl:col-span-9`}>
        {item.navigation && item.navigation.length === 1 ? (
          <div css={tw`flex flex-col lg:pl-20`}>
            <div>
              <Text
                content={first(item.navigation).label}
                textType="caption"
                style={tw`font-medium`}
              />
            </div>
            <ul css={tw`grid grid-cols-2 lg:(mt-7 gap-y-7 gap-x-12)`}>
              {first(item.navigation).subLinks.map((x, i) => (
                <li css={tw`flex flex-col lg:(w-56 pr-4)`} key={i}>
                  <NavyLinkButton key={i} label={x.label} link={x.link} />
                  <Text
                    content={x.description}
                    textType="body-xs"
                    style={tw`mt-1 text-primary-400`}
                  />
                  {x.subLinks && x.subLinks.length ? (
                    <ul css={tw`mt-4 space-y-4`}>
                      {x.subLinks.map((l, z) => (
                        <li key={z}>
                          <CyanLinkButton label={l.label} link={l.link} />
                        </li>
                      ))}
                    </ul>
                  ) : undefined}
                </li>
              ))}
            </ul>
          </div>
        ) : undefined}
        {item.navigation && item.navigation.length > 1 ? (
          <div css={tw`flex flex-row w-full space-x-12 lg:ml-20`}>
            {item.navigation.map((x, i) => (
              <div key={i} css={tw`flex flex-col lg:(w-56 pr-4)`}>
                <Text content={x.label} textType="caption" style={tw`font-medium`} />
                <ul css={tw`mt-7`}>
                  {x.subLinks.map((sl, y) => (
                    <li css={tw`flex flex-col mt-5 first-of-type:mt-0`} key={y}>
                      <NavyLinkButton key={y} label={sl.label} link={sl.link} />
                      <Text
                        content={sl.description}
                        textType="body-xs"
                        style={tw`mt-1 text-primary-400`}
                      />
                      {sl.subLinks && sl.subLinks.length ? (
                        <ul css={tw`mt-4 space-y-4`}>
                          {sl.subLinks.map((l, z) => (
                            <li key={z}>
                              <CyanLinkButton label={l.label} link={l.link} />
                            </li>
                          ))}
                        </ul>
                      ) : undefined}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ) : undefined}
      </div>
      <div css={tw`hidden xl:(block my-6 col-span-4)`}>
        <div css={tw`flex flex-col w-full p-8 bg-primary-50`}>
          <div
            css={tw`mb-4 text-xs font-medium leading-caption text-primary-400 tracking-subtitle-lg`}
          >
            Latest News
          </div>
          <Link to={`/news/${item.featured.slug}`}>
            <Heading content={item.featured.headline} headingType="h5" style={tw`font-medium`} />
          </Link>
          <Text
            content={item.featured.contentNode.childMarkdownRemark.excerpt}
            style={tw`mt-4 text-sm text-primary-400`}
          />
          <Text
            content={`${format(item.featured.date, 'dd MMMM yyyy')}`}
            style={tw`mt-12 text-xs text-primary-300`}
          />
        </div>
      </div>
    </div>
  </div>
)

Dropdown.propTypes = {
  item: PropTypes.object.isRequired,
}

const DropdownNavItem = ({ item }) => (
  <li
    css={[
      css`
        ${tw`h-full`}
        &:hover {
          > button + div {
            ${tw`opacity-100 pointer-events-auto`}
          }
          > button > svg {
            ${tw`transform -rotate-180`}
          }
        }
      `,
      global`base.hover-underline`(),
    ]}
  >
    <button type="button" css={tw`flex items-center h-full`}>
      <span css={[global`typography.subtitle-lg`, tw`font-medium text-left`]}>{item.label}</span>
      {item.navigation && item.navigation.length ? (
        <Caret css={tw`ml-2 transition-transform duration-300 ease-in-out fill-primary`} />
      ) : null}
    </button>
    {item.navigation && item.navigation.length ? <Dropdown item={item} /> : null}
  </li>
)

DropdownNavItem.propTypes = {
  item: PropTypes.object.isRequired,
}

const NavItem = ({ item }) => (
  <ClassNames>
    {({ css: cx }) => (
      <li
        css={css`
          ${tw`relative h-full`}
        `}
      >
        <Link
          to={item.link.link}
          activeClassName={cx(
            css`
              &:after {
                ${tw`translate-x-0! opacity-100!`}
              }
            `
          )}
          style={[
            css`
              ${tw`flex items-center h-full`}
            `,
            global`base.hover-underline`(),
          ]}
          partiallyActive
        >
          <span css={[global`typography.subtitle-lg`, tw`font-medium`]}>{item.link.label}</span>
        </Link>
      </li>
    )}
  </ClassNames>
)

NavItem.propTypes = {
  item: PropTypes.object.isRequired,
}

const NestedNav = ({ link, forceCloseNav }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <>
      <div css={tw`flex flex-row items-center justify-between`}>
        <Heading content={link.label} headingType="h4" style={tw`font-medium`} />
        <button
          css={[tw`flex items-center justify-center h-8`]}
          onClick={() => setExpanded(!expanded)}
        >
          <CaretLarge
            css={[
              tw`mr-2 transition-transform duration-300 ease-in-out transform fill-primary`,
              expanded && tw`rotate-180`,
            ]}
          />
        </button>
      </div>

      {link.navigation && link.navigation.length ? (
        <div
          css={[
            tw`mb-0 space-y-6 overflow-hidden transition-all duration-300 ease-in-out max-h-0`,
            expanded && tw`max-h-screen mt-8 mb-12`,
          ]}
        >
          {link.navigation.map((sublink, i) => (
            <ul key={i}>
              <li>
                <Text
                  content={sublink.label}
                  textType="subtitle-lg"
                  style={tw`font-medium text-primary-400`}
                />
                {sublink.subLinks && sublink.subLinks.length ? (
                  <ul css={tw`space-y-3 mt-1.5`}>
                    {sublink.subLinks.map((child) => (
                      <li key={child.label}>
                        <Link to={child.link} onClick={forceCloseNav}>
                          <Heading content={child.label} headingType="h3" style={tw`font-light`} />
                        </Link>
                        {child.subLinks && child.subLinks.length ? (
                          <ul css={tw`mt-5 mb-8 space-y-7`}>
                            {child.subLinks.map((l) => (
                              <li key={l.label}>
                                <Button
                                  type="primary"
                                  size="xs"
                                  theme="transparent-transparent"
                                  label={l.label}
                                  link={l.link}
                                  onClick={forceCloseNav}
                                  style={css`
                                    ${tw`h-auto py-0`}
                                    span {
                                      ${tw`ml-0 text-white`}
                                    }
                                    svg {
                                      ${tw`fill-white h-2.5`}
                                    }
                                  `}
                                />
                              </li>
                            ))}
                          </ul>
                        ) : undefined}
                      </li>
                    ))}
                  </ul>
                ) : undefined}
              </li>
            </ul>
          ))}
        </div>
      ) : undefined}
    </>
  )
}

NestedNav.propTypes = {
  link: PropTypes.object.isRequired,
  forceCloseNav: PropTypes.func,
}

const CustomerArea = ({ customerArea, forceCloseNav }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <div css={tw`flex flex-col items-start w-full`}>
      <div
        role="button"
        css={tw`flex flex-row items-center py-4 hidden`} //remove hidden when Customer Service needed
        tabIndex="0"
        onClick={() => setExpanded(!expanded)}
        onKeyPress={() => setExpanded(!expanded)}
      >
        {/* <Text content={customerArea.label} textType="body-md" style={tw`mr-8 font-medium`} /> */}
        <CaretLarge
          css={[
            tw`mr-2 transition-transform duration-300 ease-in-out transform fill-primary`,
            expanded && tw`rotate-180`,
          ]}
        />
      </div>
    </div>
  )
}

CustomerArea.propTypes = {
  customerArea: LinkType,
  forceCloseNav: PropTypes.func,
}

const MobileMenu = React.forwardRef(
  (
    { isOpened, navItems, socialMediaLabel, socialMedia, customerArea, callToAction, copyright, forceCloseNav, ...props },
    ref
  ) => {
    return (
      <nav
        ref={ref}
        css={[
          global`layout.container`,
          tw`fixed inset-x-0 bottom-0 flex flex-col items-start pt-6 overflow-x-hidden overflow-y-auto transition-all duration-300 ease-out bg-transparent opacity-0 pointer-events-none top-mobile-header pb-7`,
          isOpened && tw`z-10 flex opacity-100 pointer-events-auto bg-secondary-500`,
        ]}
      >
        <div css={tw`flex flex-col justify-between w-full min-h-full`}>
          <ul css={tw`space-y-4`}>
            {navItems.map((link) => (
              <li key={link.label}>
                {link.singular ? (
                  link.link.link.indexOf('#') === 1 ?
                    <Link to={link.link.link}>
                      <Heading content={link.label} headingType="h4" style={tw`font-medium`} onClick={forceCloseNav} />
                    </Link> :
                    <Link to={link.link.link} onClick={forceCloseNav}>
                      <Heading content={link.label} headingType="h4" style={tw`font-medium`} />
                    </Link>
                ) : (
                  <NestedNav link={link} forceCloseNav={forceCloseNav} />
                )}
              </li>
            ))}
          </ul>
          <div css={tw`relative top-0 h-48 mt-8`}>
            <div css={tw`flex  flex-col items-start w-full`} onClick={forceCloseNav}>
              <Button
                type="primary"
                size="xs"
                label={callToAction.label}
                link={callToAction.link}
                theme="navy-navy"
                style={tw`left-0`}
                hideCaret />
            </div>
            <div css={tw`flex  flex-col items-start mt-8`}>
              <LanguagePicker url="https://bolttech.io" />
            </div>
          </div>
          <footer css={tw`mt-16`}>
            {/* Revert padding below to py-4 when Customer Service needed */}
            <div css={tw`flex flex-row items-center space-x-12 py-7`}>
              <Text content={socialMediaLabel} style={tw`text-base font-medium text-primary-500 w-58 whitespace-nowrap`} />
              {socialMedia &&
                socialMedia.map((s) => (
                  <Link key={s.title} to={s.accountLink.link} style={tw`mr-12 last-of-type:mr-0`}>
                    <img
                      src={s.image.url}
                      title={s.title}
                      alt={s.alt}
                      css={css`
                        ${tw`max-h-social-icon`}
                        filter: brightness(0) saturate(100%) invert(15%) sepia(22%) saturate(3594%)
                          hue-rotate(223deg) brightness(88%) contrast(117%);
                      `}
                      width={s.width}
                      height={s.height}
                    />
                  </Link>
                ))}
            </div>
            <div css={tw`text-sm leading-5 tracking-sm`}>{copyright}</div>
          </footer>
        </div>
      </nav>
    )
  }
)

MobileMenu.propTypes = {
  isOpened: PropTypes.bool,
  navItems: PropTypes.arrayOf(PropTypes.object),
  socialMedia: PropTypes.arrayOf(PropTypes.object),
  socialMediaLabel: PropTypes.string,
  customerArea: LinkType,
  callToAction: LinkType,
  copyright: PropTypes.string,
  forceCloseNav: PropTypes.func,
}

const MainNav = ({
  navItems,
  socialMediaLabel,
  socialMedia,
  customerArea,
  callToAction,
  copyright,
  isOpened,
  setIsOpened,
  handleHover,
  headerTransition,
  headerTransitionColor,
  detectHover,
}) => {
  const itemsRef = React.createRef()
  const [detectScroll, setDetectScroll] = useState(false)
  const toggleNav = useCallback(
    (val) => {
      setIsOpened(val)
      if (!val) {
        handleHover(false)
        itemsRef.current.scrollTo(0, 0)
      }
    },
    [itemsRef]
  )

  const forceCloseNav = useCallback(() => toggleNav(false), [toggleNav])

  const handleScroll = debounce(() => {
    if (window.pageYOffset === 0) {
      setDetectScroll(false)
    } else {
      setDetectScroll(true)
    }
  }, 50)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll, { passive: true })
    }
  }, [])

  return (
    <>
      {isOpened && (
        <Global
          styles={css`
            body {
              ${max.lg} {
                ${tw`overflow-hidden`}
              }
            }
          `}
        />
      )}
      <nav css={tw`hidden lg:(w-full h-full flex)`}>
        <ul
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          css={css`
            ${tw`lg:(flex flex-row space-x-6) xl:space-x-11`}
          `}
        >
          {navItems &&
            navItems.map((item, i) =>
              item.singular ? (
                <NavItem key={i} item={item} />
              ) : (
                <DropdownNavItem key={i} item={item} />
              )
            )}
        </ul>
      </nav>
      <div css={tw`flex items-center justify-end lg:hidden`}>
        <button type="button" onClick={() => toggleNav(!isOpened)}>
          <Hamburger
            isOpened={isOpened}
            color={
              headerTransitionColor === 'white' && !isOpened && !detectScroll && !detectHover
                ? theme`colors.white`
                : theme`colors.primary.500`
            }
          />
        </button>
        <MobileMenu
          ref={itemsRef}
          isOpened={isOpened}
          navItems={navItems}
          socialMediaLabel={socialMediaLabel}
          socialMedia={socialMedia}
          customerArea={customerArea}
          callToAction={callToAction}
          copyright={copyright}
          forceCloseNav={forceCloseNav}
        />
      </div>
    </>
  )
}

MainNav.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  socialMedia: PropTypes.arrayOf(PropTypes.object),
  socialMediaLabel: PropTypes.string,
  customerArea: LinkType,
  callToAction: LinkType,
  copyright: PropTypes.string,
  isOpened: PropTypes.bool,
  setIsOpened: PropTypes.func,
  handleHover: PropTypes.func,
  headerTransition: PropTypes.bool,
  headerTransitionColor: PropTypes.oneOf(['white', 'navy']),
  detectHover: PropTypes.bool,
}

export default MainNav
